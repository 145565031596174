// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap';

// Font-Awesome
@import "node_modules/font-awesome/scss/font-awesome.scss";  
@import '~@fortawesome/fontawesome-free/css/all.min.css';